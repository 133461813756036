<template>
	<div>
		<v-btn color="primary" @click.stop="showAddGroupDialog">Добавить группу</v-btn>

		<v-dialog v-model="addGroupDialog" width="500">
			<v-card>
				<v-card-title class="headline lighten-2">Добавить новую группу</v-card-title>
				<v-card-text>
					<v-form class="form" ref="addGroupForm">
						<v-text-field
							v-model="groupName"
							label="Название группы"
							:rules="[v => !!v || 'Required!']"
							required
						></v-text-field>
						<v-row class="mx-0">
							<v-text-field
								v-model="ageGroupStart"
								@change="ageGroupEnd && ageGroupEnd <= ageGroupStart ? ageGroupStart = +ageGroupEnd - 1 : ''"
								label="Возрастная группа от"
								type="number"
								:rules="[v => !!v || 'Required!']"
								required
								class="mr-4"
							></v-text-field>
							<v-text-field
								v-model="ageGroupEnd"
								@change="ageGroupStart && ageGroupStart >= ageGroupEnd ? ageGroupEnd = +ageGroupStart + 1 : ''"
								label="Возрастная группа до"
								type="number"
								:rules="[v => !!v || 'Required!']"
								required
							></v-text-field>
						</v-row>
						<v-select
							v-model="groupLangSelected"
							:items="groupLangList"
							item-text="name"
							item-value="id"
							:rules="[v => typeof v !== 'object' || 'Required!']"
							label="Язык обучения">
						</v-select>
						<v-select
							v-model="groupTypeSelected"
							:items="groupTypeList"
							item-text="name"
							item-value="id"
							:rules="[v => typeof v !== 'object' || 'Required!']"
							label="Вид группы">
						</v-select>
						<v-select
							v-model="groupHourSelected"
							:items="groupHourList"
							item-text="name"
							item-value="id"
							:rules="[v => typeof v !== 'object' || 'Required!']"
							label="Режим работы">
						</v-select>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="addGroupSubmit">
						Добавить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-divider class="my-4"></v-divider>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			class="elevation-1 request_list">
		</v-data-table>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getData();
		},
		data: () => ({
			isLoading: true,
			requestHeaders: [],
			requestList: [],
			addGroupDialog: false,
			ageGroupStart: null,
			ageGroupEnd: null,
			groupLangList: [],
			groupLangSelected: {},
			groupTypeList: [],
			groupTypeSelected: {},
			groupHourList: [],
			groupHourSelected: {},
			groupName: ''
		}),
		methods: {
			handleClick(item) {
				this.$router.push({ path: '/kindergarten/group/'+item.id });
			},
			async getData() {
				await this.$http.get('/group/all?currPage=1').then((response) => {
					this.requestHeaders = response.data.data.kindergartenGroupList.headers;
					this.requestList = response.data.data.kindergartenGroupList.list;
					this.isLoading = false;
				});
			},
			async showAddGroupDialog() {
				if(!this.groupLangList.length) {
					await this.$http.get('/group/lang').then((response) => {
						if(response.data.status == 'success') {
							this.groupLangList = response.data.data;
						}
					});
				}

				if(!this.groupTypeList.length) {
					await this.$http.get('/group/type').then((response) => {
						if(response.data.status == 'success') {
							this.groupTypeList = response.data.data;
						}
					});
				}

				if(!this.groupHourList.length) {
					await this.$http.get('/group/hour').then((response) => {
						if(response.data.status == 'success') {
							this.groupHourList = response.data.data;
						}
					});
				}

				this.addGroupDialog = true;
			},
			async addGroupSubmit() {
				if(this.$refs.addGroupForm.validate()) {
					await this.$http.post('/group/add', {
						"start_age": this.ageGroupStart,
						"end_age": this.ageGroupEnd,
						"kgtId": this.groupTypeSelected,
						"ohId": this.groupHourSelected,
						"lang": this.groupLangSelected,
						"name": this.groupName
					}).then((response) => {
						if(response.data.status == 'success') {
							this.getData().then(() => {
								this.addGroupDialog = false;
							});
						} else {
							console.log(response.data.message);
						}
					});
				}
			}
		},
		watch: {
			addGroupDialog: function (v) {
				if(!v) {
					//this.$refs.addGroupForm.reset();
					//this.groupTypeSelected = {};
					//this.groupLangSelected = {};
					//this.groupHourSelected = {};
					this.groupName = '';
				}
			}

		}
  }
</script>

<style scoped>
	/*.request_list tr {
		cursor: pointer !important;
	}*/
</style>